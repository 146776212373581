<template>
    <div class="container-fluid" v-if="procedure">
        <div class="row align-items-center my-2">
            <div class="col-9">
                <h4 class="m-1 float-left mr-2">{{ procedure.displayCategory }}</h4>
            </div>
            <div class="col-3">
                <b-button class="pull-right" size="sm" v-b-toggle.collapse-1>Change</b-button>
            </div>
        </div>
        <b-collapse id="collapse-1" class="mt-2">
            <div class="row mb-2">
                <div class="col-12">
                    <b-list-group>
                        <b-list-group-item v-for="(item, i) in categories" :key="i"
                            :href="bindRouteToProcedure(item.id)" :active="isCategoryActive(item.id)">{{
        item.name
    }}</b-list-group-item>
                    </b-list-group>
                </div>
            </div>
        </b-collapse>
        <div class="card">
            <div class="card-body">
                <div v-html="procedure.procedureText"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    externalData
} from '@state/api/pwaexternal';

export default {
    data() {
        return {
            procedure: undefined,
            categories: []
        }
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
        designationId() {
            return this.$route.params.designationId;
        },
        categoryId() {
            return this.$route.params.categoryId;
        },
    },
    async created() {
        await this.loadProcedure();
        await this.loadCategories();
    },
    methods: {
        bindRouteToProcedure(categoryId) {
            return `/${this.locationId}/designation/${this.designationId}/selectedCategory/${categoryId}`
        },
        isCategoryActive(categoryId) {
            if (categoryId == this.procedure.category) {
                return true
            }
            return false
        },
        async loadCategories() {
            this.categories = await externalData.searchCategory(this.locationId);
        },
        async loadProcedure() {
            this.procedure = await externalData.searchProcedure(this.locationId, this.designationId, this.categoryId);
            if (!this.procedure) {
                this.$vs.notify({
                    title: "Error",
                    text: "No Procedure found, please contract administrator",
                    color: "danger",
                });
            }
        },
    }
}
</script>

<!-- <style>
.pull-right {
    float: right;
}
</style> -->